import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import Notifications from '@kyvg/vue3-notification'
import VueNumber from 'vue-number-animation'
import VueParticles from 'vue-particles'
import { createI18n } from 'vue-i18n'
import en from '../src/translations/en'
import bih from '../src/translations/bih'



const i18n = createI18n({
    locale: 'en', // Početni jezik
    messages: {
        en: {
          message: en
        },
        bih: {
          message: bih
        },
      },
  })

createApp(App).use(router).use(i18n).use(VueNumber).use(VueParticles).use(Notifications).use(VCalendar, {}).mount('#app')
