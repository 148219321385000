<template>
  <div class="DisableSmallDevices">
    <p>{{ $t('message.NotVisible') }}</p>
  </div>
  <router-view />

  <notifications position="bottom right" classes="my-notification" />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
html,
body {
  margin: 0px !important;
  padding: 0px !important;
  overflow: hidden;
}

.DisableSmallDevices {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #12151e;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  display: none;
}

.DisableSmallDevices > p{
  font-family: Roboto;
  font-size:35px;
  color: #353c52;
  font-weight: bold;
  padding: 20px;
 }
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #302c3c;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  color: rgb(70, 70, 70);
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 1px 1px 0.5px 1px grey;
  border-left: 3px solid rgb(55, 177, 30);
  margin-bottom: 10px;

  &.success {
    background: #7af19e;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
    color: rgb(71, 71, 71);
  }

  &.error {
    background: #fd6e64;
    border-left-color: #b82e24;
    color: white;
  }
}
@media screen and (max-width: 800px) {
  .DisableSmallDevices {
    display: flex;
  justify-content: center;
  align-items: center;
  }
}
</style>
