import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/portal",
    name: "portal",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoggedView.vue"),
    children: [
      { path: "/portal", component: () => import("../parentComponents/Home") },
      {
        path: "notifications",
        component: () => import("../parentComponents/Notifications"),
      },
      {
        path: "members",
        component: () => import("../parentComponents/Members"),
      },
      {
        path: "initiativesandproposals",
        component: () => import("../parentComponents/Proposals"),
      },
      {
        path: "jobdashboard",
        component: () => import("../parentComponents/JobDashboard"),
      },
      { path: "events", component: () => import("../parentComponents/Events") },
      { path: "ourFactory", component: () => import("../parentComponents/OurFactory") },
      { path: "ourProducts", component: () => import("../parentComponents/OurProducts") },
      {
        path: "documents",
        component: () => import("../parentComponents/Documents"),
      },
      {
        path: "literature",
        component: () => import("../parentComponents/Literature"),
      },
      {
        path: "reservations",
        component: () => import("../parentComponents/Reservations"),
      },
      { path: "news", component: () => import("../parentComponents/News") },
      { path: "myteam", component: () => import("../parentComponents/MyTeam") },
      {
        path: "settings",
        component: () => import("../parentComponents/Settings"),
      },
      {
        path: "cms/userManagement",
        component: () => import("../components/Cms/UserManagement"),
      },
      {
        path: "cms/notifications",
        component: () => import("../components/Cms/Notifications"),
      },
      {
        path: "cms/events",
        component: () => import("../components/Cms/Events"),
      },
      { path: "cms/pool", component: () => import("../components/Cms/Pool") },
      {
        path: "cms/injury",
        component: () => import("../components/Cms/Injury"),
      },
      {
        path: "cms/importantDates",
        component: () => import("../components/Cms/ImportantDates"),
      },
      {
        path: "cms/jobsDashboard",
        component: () => import("../components/Cms/JobsDashboard"),
      },
      { path: "cms/news", component: () => import("../components/Cms/News") },
      {
        path: "cms/newproduct",
        component: () => import("../components/Cms/NewProduct"),
      },
      {
        path: "cms/restaurant",
        component: () => import("../components/Cms/Restaurant"),
      },
      {
        path: "cms/documents",
        component: () => import("../components/Cms/Documents"),
      },
      {
        path: "cms/literature",
        component: () => import("../components/Cms/Literature"),
      },
      {
        path: "cms/reservations",
        component: () => import("../components/Cms/Reservations"),
      },
      {
        path: "cms/initiative",
        component: () => import("../components/Cms/Initiative"),
        children: [
          {
            path: "approved",
            component: () => import("../components/Cms/Initiatives/Approved"),
          },
          {
            path: "pending",
            component: () => import("../components/Cms/Initiatives/Pending"),
          },
          {
            path: "denied",
            component: () => import("../components/Cms/Initiatives/Denied"),
          },
        ],
      },
      {
        path: "syndicate",
        component: () => import("../parentComponents/Syndicate"),
      },
      {
        path: "cms/photoGallery",
        component: () => import("../components/Cms/PhotoGallery"),
      },
      {
        path: "cms/videoGallery",
        component: () => import("../components/Cms/VideoGallery"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
